<template>
  <div v-if="template">
    <v-breadcrumbs v-if="!isEmbedded" :items="breadcrumbItems"/>
    <v-card :elevation="isEmbedded ? 0 : 2">
      <page-header v-if="template" :title="template.name" :img="template.img" />

      <v-card-text>
        <vue-markdown class="markdown mb-4" :html="false" :source="template.description" />
        <v-btn color="primary" :to="{name: 'eventmanagerStart', query: {tags: 'WELLBEING_PACK', wellbeingPackId: template.id }}">Start This Challenge</v-btn>
      </v-card-text>

      <v-card-title class="subheader">Achievements</v-card-title>
      <v-card-text>Participants can log the following achievement during the challenge.</v-card-text>
      <v-list>
        <template v-for="(item, idx) in badges">
          <v-list-item :key="`li-${idx}`">
            <v-list-item-icon>
              <img v-if="item.img" :src="item.img" width="75px" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.description">
                <vue-markdown class="markdown mb-0" :html="false" :source="item.description" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`sep-${idx}`" />
        </template>
      </v-list>

      <div class="green white--text py-6 py-md-12 my-4">
        <div class="container">
          <v-row>
            <v-col cols="12" md="12">
              <h2 class="subtitle">Go Live Today!</h2>
              <h1 class="subtitle">Create your personalized fitness challenge within minutes!</h1>
              <p class="mb-0 mt-4">
                <v-btn class="mr-4 mb-4" color="white" :to="{name:'startTrial'}">Start Free Trial</v-btn>
                <v-btn v-if="false" class="mr-4 mb-4" color="white" href="/eventmanager/create">Create Your Event</v-btn>
                <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
              </p>
            </v-col>
          </v-row>
        </div>
      </div>

      <v-card-title class="subheader">More content packs</v-card-title>
      <v-card-text>Explore more ready-to-go content packs.</v-card-text>
      <v-slide-group
        multiple
        show-arrows
      >
        <v-slide-item
          v-for="(item, idx) in templates.filter(x => x.id != template.id)"
          :key="idx"
        >
          <WellbeingPackCard :item="item" :to="{name: 'challengeWellbeingPackView', params: {id : item.id }}" class="ma-2" style="width:200px"/>
        </v-slide-item>
      </v-slide-group>      

    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';

import assetsService from "@/services/assetsService";
import eventService from "@/services/eventService";
import PageHeader from '@/components/PageHeader.vue'
import VueMarkdown from '@/components/VueMarkdown.vue'
import RaceResultsMap from "@/components/RaceResultsMap.vue";
import siteData from '@/data/site.json'
import ShareButton from "@/components/ShareButton";
import EventTemplateCard from '@/components/templates/EventTemplateCard.vue'
import WellbeingPackCard from '@/components/templates/WellbeingPackCard.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "PreDesignedTemplate",
  components: {
    VueMarkdown,
    PageHeader,
    RaceResultsMap,
    ShareButton,
    WellbeingPackCard,
    EventTemplateCard,
  },
  props: {
  },
  data() {
    return {
      dark: false,
      templates: null,
      template: null,
      badges: null,
      event: null,
      race: null,
    };
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    this.badges = null;
    await this.load(to.params.id);
    console.log('TO', to.params.raceId, this.race, this.breadcrumbItems);
    next();
  },
  async mounted() {
    await this.load(this.$route.params.id);
  },
  methods: {
    async load(templateId) {
      this.templates = (await assetsService.getWellbeingPacks()).data.data;
      this.template = this.templates.find(x => x.id == templateId);
      this.event = { id: this.template.id, name: this.template.name };
      this.race = this.template;//{ id: this.template.id, name: this.template.name, route: this.template.route };
      this.badges = this.template.badges;
      console.log('/// template', this.race, this.badges);
      //if (this.$refs.resultMap) this.$refs.resultMap.loadMap();
      await this.updateMetaTags();
    },

    async updateMetaTags() {
      const meta = {
        title: `View the ready-to-go wellbeing content pack ${this.template.name} on ${tenant.name}.`, 
        description: this.template.description,
        image: this.template.img,
      }; 
      EventBus.$emit('page-header-change', meta);
    },


  },
  watch: {
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    
    breadcrumbItems() { 
      return !this.template  ? [] : [
        { text: 'Ready-to-go challenges', exact: true, to: { name: 'challenges'} },
        { text: 'Wellbeing packs', exact: true, to: { name: 'challengeWellbeingPacks'} },
        { text: this.template.name, disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed';
    },
  },

};
</script>

<style lang="scss">
  .logo-container { overflow: inherit !important; margin-bottom: 42px;}
  .logo-container .v-responsive__sizer { padding-bottom: 0!important;}
  .logo-container .display-2 { display: inline-block; margin-left: 250px;}
  .logo-container.container-xs .display-2 { margin-left: 150px;}
  .logo-container.container-sm .display-2 { margin-left: 200px;}
  .event-logo { background-color: white; display: inline-block !important; left: 16px; position: absolute !important; bottom: -32px; }
  .event-logo img { max-width: 200px; }
  .container-xs .event-logo img { max-width: 100px; }
  .container-sm .event-logo img { max-width: 150px; }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

